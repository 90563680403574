import React from 'react'
import "./Loader.css"

function Loader() {
  return (
    <div>
      <div className="pageloader">
        <img src="/assets/images/Poeticatma_logo.png" alt="Logo" />
        <span></span>
      </div>
    </div>
  )
}

export default Loader
